import React, { SyntheticEvent } from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import { stubTrue } from 'lodash';
import {
  emailValidator,
  ipAddressValidator,
} from 'lib/validators';
import FormFrame from 'features/ui/FormFrame';
import { formFieldsBuilder } from 'features/ui/FormFields';
import { InfoRowText } from 'features/ui/InfoRow';
import TagInput from 'features/ui/TagInput';
import Input from 'features/ui/Input';
import FormRow from 'features/ui/FormRow';
import { IOffer, IOfferSettingsRegistrationFormValues } from '../types';

interface IFormProps extends FormikProps<IOfferSettingsRegistrationFormValues>, WithTranslation {
  id?: string;
  offer?: IOffer;
}

const Reg = formFieldsBuilder({ labelPrefix: 'reg_' });

class BaseForm extends React.Component<IFormProps> {
  uniqueValidation(value: string, list: string[], field: string) {
    const { t } = this.props;
    if (list.includes(value)) {
      // eslint-disable-next-line no-throw-literal
      throw {
        errors: [t(
          'forms:wrong_field_already_has_element',
          { value, field: t(field) },
        )],
      };
    }
  }

  validateIpMaskFilter = (value: string, list: string[]) => {
    let result = false;
    const { t, setFieldError } = this.props;
    try {
      const normValue = value.trim();
      this.uniqueValidation(normValue, list, 'reg_ip_mask_filter');
      ipAddressValidator({ field: t('reg_ip_mask_filter') }).validateSync(normValue);
      result = true;
    } catch (e) {
      setFieldError('ip_mask_filter', e.errors[0]);
    }
    return result;
  }

  validateEmailMaskFilter = (value: string, list: string[]) => {
    let result = false;
    const { t, setFieldError } = this.props;
    try {
      const normValue = value.trim();
      this.uniqueValidation(normValue, list, 'reg_email_mask_filter');
      emailValidator({ field: t('reg_email_mask_filter') }).validateSync(normValue);
      result = true;
    } catch (e) {
      setFieldError('email_mask_filter', e.errors[0]);
    }
    return result;
  }

  onChangeIpMaskFilter = (data: string[]) => {
    const { setFieldValue } = this.props;
    setFieldValue('ip_mask_filter', data.map(d => d.trim()));
  }

  onChangeUsersTtl = (data: SyntheticEvent) => {
    const { setFieldValue } = this.props;
    setFieldValue('default_users_ttl', +data.target.value.trim());
  }

  onChangeEmailMaskFilter = (data: string[]) => {
    const { setFieldValue } = this.props;
    setFieldValue('email_mask_filter', data.map(d => d.trim()));
  }

  onChangePasswordMinValue = (data: SyntheticEvent) => {
    const { setFieldValue } = this.props;
    setFieldValue('password_min_length', +data.target.value);
  }

  handleChangePasswordRule = (data: SyntheticEvent) => {
    const { setFieldValue } = this.props;
    setFieldValue('password_rule', data.target.value);
  }

  renderUsersAllowed() {
    const { values } = this.props;
    if (values.registration_unlimited) {
      return null;
    }
    return (
      <Reg.Input
        name="users_allowed"
        type="number"
        form={this.props}
        disabled={values.registration_unlimited}
      />
    );
  }

  renderLabUsersAllowed() {
    const { values } = this.props;
    if (values.registration_unlimited) {
      return null;
    }
    return (
      <Reg.Input
        name="alpina_lab_users_allowed"
        type="number"
        form={this.props}
        disabled={values.alpina_lab_users_allowed}
      />
    );
  }

  renderAlpinaLabRegistration = () => {
    const {
      setFieldValue,
      values: {
        registration_enabled,
        registration_by_email_enabled,
        pins_enabled,
        alpina_lab_registration_enabled,
        alpina_lab_enabled,
      },
    } = this.props;

    let alpinaLabReqEnabled = false;
    if (alpina_lab_enabled && (registration_by_email_enabled || pins_enabled || registration_enabled)) {
      alpinaLabReqEnabled = true;
    }
    const form = this.props;

    if (alpina_lab_registration_enabled && !alpinaLabReqEnabled) {
      setFieldValue('alpina_lab_registration_enabled', false);
    }

    return (
      <Reg.Switch
        name="alpina_lab_registration_enabled"
        form={form}
        disabled={!alpinaLabReqEnabled}
        shouldUpdate={stubTrue}
        text="Доступно при включенной регистрации в Лаб и активированной кнопке регистрации по PIN-коду, email или через промо-лендинги"
      />
    );
  }

  render() {
    const {
      t,
      id,
      values,
      offer,
    } = this.props;
    const form = this.props;
    return (
      <FormFrame
        id={id}
        cancelLink="/offers"
        {...this.props}
      >
        <Reg.Switch name="users_import_enabled" form={form} />
        <Reg.Switch name="registration_welcome_mail" form={form} />
        <Reg.Switch name="send_b2b_registration_mail" form={form} />
        <Reg.Switch name="groups_enabled" form={form} />
        <Reg.Switch name="group_required" form={form} />
        <Reg.Switch name="confirmation_required" form={form} />
        <Reg.Switch name="pins_enabled" form={form} />
        <Reg.Switch name="registration_by_email_enabled" form={form} />
        <Reg.Switch name="registration_enabled" form={form} />
        <Reg.Switch name="alpina_lab_enabled" form={form} />
        {this.renderAlpinaLabRegistration()}
        <InfoRowText label={t('offer_prefix_registration_link')} value={offer ? `https://login.alpinadigital.ru/offer/${offer.prefix}` : '-'} />
        <hr />
        <Reg.Row
          name="default_users_ttl"
          text={t('default_users_ttl')}
          form={form}
        >
          <Input
            name="default_users_ttl"
            type="number"
            min="0"
            value={values.default_users_ttl}
            onChange={this.onChangeUsersTtl}
            onBlur={this.onChangeUsersTtl}
          />
        </Reg.Row>
        <InfoRowText label={t('reg_users_registered')} value={values.users_registered} />
        <Reg.Switch name="password_change_enabled" form={form} />
        <Reg.Row
          name="password_min_length"
          text={t('reg_password_min_length')}
          form={form}
        >
          <Input
            id="password_min_length"
            type="number"
            min="0"
            value={values.password_min_length}
            onChange={this.onChangePasswordMinValue}
            onBlur={this.onChangePasswordMinValue}
          />
        </Reg.Row>
        <FormRow
          id="password_rule"
          label={t('reg_password_rule')}
        >
          <Input
            type="select"
            id="password_rule"
            name="password_rule"
            onChange={this.handleChangePasswordRule}
            onBlur={this.handleChangePasswordRule}
            value={values.password_rule}
          >
            <option value="any">{t('rules_any')}</option>
            <option value="alfabit">{t('rules_alfabit')}</option>
            <option value="alfabit_digits">{t('rules_alfabit_digits')}</option>
            <option value="alfabit_digits_symbols">{t('rules_alfabit_digits_symbols')}</option>
          </Input>
        </FormRow>
        <Reg.Switch name="registration_unlimited" form={form} />
        {this.renderUsersAllowed()}
        {this.renderLabUsersAllowed()}
        <hr />
        <Reg.Row
          name="ip_mask_filter"
          text={t('reg_ip_mask_filter_example')}
          form={form}
        >
          <TagInput
            name="ip_mask_filter"
            value={values.ip_mask_filter}
            placeholder="127.0.0.1"
            onChange={this.onChangeIpMaskFilter}
            onBlur={this.onChangeIpMaskFilter}
            onValidation={this.validateIpMaskFilter}
          />
        </Reg.Row>

        <Reg.Row
          name="email_mask_filter"
          text={t('reg_email_mask_filter_example')}
          form={form}
        >
          <TagInput
            name="email_mask_filter"
            value={values.email_mask_filter}
            placeholder="@example.com"
            onChange={this.onChangeEmailMaskFilter}
            onBlur={this.onChangeEmailMaskFilter}
            onValidation={this.validateEmailMaskFilter}
          />
        </Reg.Row>
      </FormFrame>
    );
  }
}

export default withTranslation('offers')(BaseForm);
