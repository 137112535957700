export default {
  grid: {
    create: '<0>Создать</0>',
  },
  items: {
    authors: 'Авторы',
    badge: 'Бейдж',
    bisac: 'BISAC',
    categories: 'Категории',
    copyright_expire: 'Дата окончания прав',
    description: 'Аннотация',
    external_id: 'ИМАГ ID',
    external_link: 'ИМАГ URL',
    image: 'Обложка',
    image_wide: 'Широкая обложка',
    is_active: 'Активно?',
    is_offer_specific: 'Запрещено автоматически добавлять в предложение',
    is_hidden: 'Скрывать айтем из выдачи',
    items: 'Итемы',
    language: 'Язык',
    name: 'Название',
    offers: 'Предложения',
    original_name: 'Название на языке оригинала',
    position_bestsellers: 'Позиция в бестселлерах/популярном',
    position_latest: 'Позиция в новинках',
    prices: 'Цены',
    provider: 'Провайдер',
    publisher: 'Издательство',
    relatedSecondaryItems: 'Связанный контент',
    recommendedSecondaryItems: 'Рекомендуемый контент',
    recommendation_description: 'Описание рекомендуемого контента',
    tags: 'Теги',
    year: 'Год издания (из макета)',
    age_limit: 'Возрастное ограничение',
    recommended_size: 'Рекомендуемый размер {{total}} символов, сейчас {{amount}}',
    measure_0: '{{count}} Итем',
    measure_1: '{{count}} Итема',
    measure_2: '{{count}} Итемов',
    subscriptions: 'Подписки',
    types: {
      book: 'Книга',
      hybrid: 'Гибрид',
      document: 'Документ',
      audiobook: 'Аудиокнига',
      course: 'Курс',
      test: 'Тест',
      video: 'Видео',
      collection: 'Коллекция',
      event: 'Мероприятие',
      article: 'Статья',
    },
    grid: {
      title: 'Все итемы',
    },
  },
  books: {
    content: 'ePub файл',
    isbn: 'ISBN',
    paper_pages: 'Количество страниц бумажной книги',
    type: 'Тип книги',
    books: 'Книги',
    full: 'Книга',
    summary: 'Саммари',
    grid: {
      title: 'Книги',
    },
  },
  hybrids: {
    audio_time: 'Длительность (сек.)',
    isbn: 'ISBN',
    content: 'Архив для гибрида',
    hybrids: 'Гибриды',
    paper_pages: 'Количество страниц бумажной книги',
    grid: {
      title: 'Гибридные книги (аудио + текст)',
    },
  },
  documents: {
    content: 'PDF-файл',
    documents: 'Документы',
    is_important: 'Важный документ',
    isbn: 'ISBN',
    grid: {
      title: 'PDF',
    },
  },
  audiobooks: {
    audiobooks: 'Аудиокниги',
    type: 'Тип аудиокниги',
    content: 'Аудио-архив',
    narrator: 'Чтец',
    duration: 'Длительность аудио',
    isbn: 'ISBN',
    full: 'Аудиокнига',
    summary: 'Аудиосаммари',
    grid: {
      title: 'Аудиокниги',
    },
    is_preview: 'Превью',
  },
  video: {
    content: 'Видео-файл',
    type: 'Тип видео',
    video: 'Видео',
    duration: 'Длительность видео',
    full: 'Видео',
    summary: 'Мини-курс',
    awaitLoading: 'Файл обрабатывается и появится в ближайшее время',
    grid: {
      title: 'Видео',
    },
  },
  course: {
    type: 'Тип курс',
    course: 'Курс',
    stages: 'Разделы',
    duration: 'Длительность',
    grid: {
      title: 'Курс',
    },
  },
  article: {
    type: 'Тип статья',
    content: 'Содержание статьи',
    article: 'Статья',
    grid: {
      title: 'Статья',
    },
  },
  test: {
    type: 'Тип тест',
    test: 'Тест',
    test_content: 'Вопросы',
    attempts_count: 'Количество попыток',
    grid: {
      title: 'Тест',
    },
  },
  collections: {
    childrenItems: 'Итемы',
    collections: 'Коллекции',
    is_important: 'Важная коллекция',
    grid: {
      title: 'Коллекции',
    },
  },
  events: {
    type: 'Тип книги',
    duration: 'Длительность мероприятия',
    duration_time: 'Время проведения',
    started_at: 'Начало мероприятия',
    finished_at: 'Конец мероприятия',
    link: 'Внешняя ссылка мероприятия',
    is_all_speakers: 'Все пользователи спикеры',
    is_autorecord: 'Автоматическая запись трансляции',
    autorecord_text: 'Запись начнется после того, как в мероприятие войдет хотя бы один модератор. Запись автоматически закончится, после того как из него выйдет последний участник или по нажатию на кнопку Остановить запись',
    positive_value: 'Поле должно быть больше нуля',
    description: 'Описание связанных материалов',
    moderators: 'Модераторы',
    speakers: 'Спикеры',
    blockedUsers: 'Заблокированные пользователи',
    recommended_time: 'Установите время по Московскому часовому поясу',
    tooltip: 'На экран мероприятия пользователи смогут зайти за 10 минут до указанного времени, и лишатся этой возможности через 30 минут после времени завершения, указанного ниже',
    event_link_pattern: 'Ссылка должна начинаться с https и заканчиваться на m3u8',
    event_link_required: 'Ссылка на трансляцию обязательна!',
    source: 'Тип трансляции',
    source_default: 'Выберите тип трансляции',
    vk: 'Вконтакте',
    webinar: 'Webinar.ru',
    self_streaming: 'Потоковое видео формата m3u8',
    grid: {
      title: 'Мероприятия',
    },
  },
  offers: {
    all_offers: 'Все предложения',
    amount_of_users: 'Количество пользователей',
    contacts: 'Контакты',
    branding: 'Фирменное оформление',
    publishers: 'Издательства',
    providers: 'Провайдеры',
    settingsBranding: {
      color_banner_arrow: 'Цвет стрелок на баннерах предложения',
      color_banner_bar: 'Цвет полосы прокрутки под баннерами предложения',
      color_main: 'Основной цвет предложения',
      color_pressed: 'Второй дополнительный цвет (например, цвет тегов в поиске при выделении)',
      color_selected: 'Цвет футера в предложении',
      color_unpressed: 'Дополнительный цвет (например, цвет кнопок при наведении)',
      custom_titles: 'Кастомные заголовки в предложении',
      custom_titles_en: 'Кастомный заголовок на английском языке',
      custom_titles_ru: 'Кастомный заголовок на русском языке',
      support_email: 'Контактный e-mail',
      logoBottom: 'Нижний логотип предложения',
      logoTop: 'Верхний логотип предложения',
      appIcon: 'Иконка приложения предложения',
      splashLogoTop: 'Верхний логотип стартового экрана приложения предложения',
      splashLogoBottom: 'Нижний логотип стартового экрана приложения предложения',
      title_registration: 'Окно регистрации',
      title_login: 'Окно авторизации',
      title_latest: 'Раздел "Новинки"',
      title_most_readable: 'Раздел "Популярное"',
      title_books: 'Раздел "Книги"',
      title_hybrids: 'Раздел "Гибриды"',
      title_documents: 'Раздел "Документы"',
      title_collections: 'Раздел "Компетенции"',
      title_videos: 'Раздел "Видео"',
      title_audiobooks: 'Раздел "Аудиокниги"',
    },
    base_domain_ru: 'alpinadigital.ru',
    base_domain_io: 'alpinadigital.io',
    base_domain_text: 'Доменная зона',
    build: 'Сборка',
    build_analytics: 'Настройки сервисов аналитики предложения',
    build_bundle_id: 'Идентификатор приложения предложения в AppStore/GooglePlay',
    build_main_id: 'ID предложения в приложении',
    build_main_prefix: 'Префикс предложения в приложении',
    build_features_corporate: 'Приложение по функционалу является корпоративной библиотекой',
    build_features_in_app_purchase: 'Включен функционал внутренних покупок',
    build_features_books: 'Включен функционал витрины',
    build_features_content_table: 'Включен функционал оглавления книг/гибридов',
    build_features_documents: 'Включен функционал документов',
    build_features_banners: 'Включен функционал баннеров',
    build_features_badges: 'Включен функционал бейджей',
    build_features_wishlist: 'Включен функционал избранного',
    build_features_comments: 'Включен функционал комментариев',
    build_features_push_notification: 'Включен функционал push-уведомлений',
    build_features_local_notification: 'Включен функционал локальных уведомлений',
    build_features_required_login: 'Функционал подразумевает обязательный вход',
    build_features_socials: 'Включен функционал входа через социальные сервисы',
    build_features_pins: 'При регистрации включен функционал пин-кодов',
    build_features_user_groups: 'При регистрации включен функционал групп пользователей',
    build_features_analytic: 'Включен функционал сервисов аналитики',
    build_features_app_rate: 'Включен функционал оценки приложения системным сервисом',
    build_interface_title_showcase: 'Заголовок витрины',
    build_interface_title_my_collection: 'Заголовок "моей коллекции"',
    build_interface_title_documents: 'Заголовок раздела документов',
    build_interface_title_my_documents: 'Заголовок "моих документов"',
    build_branding_title: 'Брендирование названия приложения',
    build_branding_splash_title: 'Заголовок стартового экрана приложения',
    build_branding_splash_subtitle: 'Подзаголовок стартового экрана приложения',
    build_branding_primary_color: 'Основной цвет в приложении',
    build_branding_bar_color: 'Цвет полос прокрутки в приложении',
    build_branding_selected_button_color: 'Цвет выделенной кнопки',
    build_branding_unselected_button_color: 'Цвет невыделенной кнопки',
    build_branding_background_splash_color: 'Фоновый цвет стартового экрана',
    build_branding_splash_text_color: 'Цвет текста заголовка на стартовом экране',
    build_branding_splash_subtitle_color: 'Цвет текста подзаголовка на стартовом экране',
    build_branding_primary_dark_color: 'Основной темный цвет Android-приложения',
    build_branding_primary_light_color: 'Основной светлый цвет Android-приложения',
    build_branding_accent_color: 'Основной цвет выделения текста Android-приложения',
    build_branding_accent_dark_color: 'Темный цвет выделения текста Android-приложения',
    build_branding_text_primary_color: 'Основной цвет текста Android-приложения',
    build_branding_secondary_text_color: 'Вторичный цвет текста Android-приложения',
    build_branding_icons_color: 'Цвет иконок Android-приложения',
    build_branding_divider_color: 'Цвет разделителей Android-приложения',
    build_branding_background_light_color: 'Светлый цвета фона Android-приложения',
    build_ios_bundle: 'Bundle ID проекта приложения',
    build_ios_team: 'Team ID команды разработки приложения',
    build_ios_apple_app_id: 'ID приложения в AppStore',
    build_externals: 'Настройки внешних сервисов приложения',
    build_externals_type: 'Тип внешнего сервиса приложения',
    build_externals_enabled: 'Внешний сервис приложения активен',
    build_externals_key: 'Идентификатор внешнего сервиса приложения',
    build_externals_secret: 'Ключ внешнего сервиса приложения',
    build_externals_domain: 'Домен внешнего сервиса приложения',
    build_externals_content: 'Контент',
    build_socials: 'Социальные настройки предложения',
    build_socials_type: 'Тип социального сервиса приложения',
    build_socials_enabled: 'Социальный сервис приложения активен',
    build_socials_key: 'Идентификатор социального сервиса приложения',
    build_analytics_type: 'Тип сервиса аналитики',
    build_analytics_enabled: 'Сервис аналитики приложения активен',
    build_analytics_key: 'Идентификатор сервиса аналитики приложения',
    content: 'Контент',
    content_settings: 'Настройки контента',
    content_audiobooks_enabled: 'Использование аудиокниг',
    content_events_enabled: 'Использование мероприятий',
    content_audiobooks_max_count: 'Максимальное количество аудиокниг (шт.)',
    content_audiobooks_max_size: 'Максимальный размер аудиокниги при загрузке',
    content_courses_enabled: 'Использование курсов',
    content_articles_enabled: 'Использование статей',
    content_games_enabled: 'Использование игр',
    content_tests_enabled: 'Использование тестов',
    content_books_download_limit: 'Лимит скачивания Итемов (шт.)',
    content_events_calendar_enabled: 'Расписание стримов',
    content_books_download_epub_enabled: 'Скачивание EPUB-версий книг',
    content_books_download_pdf_enabled: 'Скачивание PDF-версий книг',
    content_books_download_mobi_enabled: 'Скачивание MOBI-версий книг',
    content_books_enabled: 'Использование книг',
    content_books_max_count: 'Максимальное количество книг (шт.)',
    content_latest_enabled: 'Новинки',
    content_popular_enabled: 'Популярное',
    content_books_max_size: 'Максимальный размер книги при загрузке',
    content_categories_enabled: 'Отдельные категории',
    content_categories_max_count: 'Максимальное количество отдельных категорий',
    content_collections_enabled: 'Использование коллекций',
    content_collections_max_count: 'Максимальное количество коллекций (шт.)',
    content_documents_download_limit: 'Лимит скачивания документов',
    content_documents_enabled: 'Использование документов',
    content_documents_max_count: 'Максимальное количество документов (шт.)',
    content_documents_max_size: 'Максимальный размер документа при загрузке',
    content_hybrids_download_limit: 'Лимит скачивания гибридов',
    content_hybrids_enabled: 'Использование гибридов',
    content_hybrids_max_count: 'Максимальное количество гибридов (шт.)',
    content_hybrids_max_size: 'Максимальный размер гибрида при загрузке',
    content_items_enabled: 'Создание элементов',
    content_videos_enabled: 'Использование видео',
    content_videos_max_count: 'Максимальное количество видео (шт.)',
    content_videos_max_size: 'Максимальный размер видео при загрузке',
    go_to_items: 'Перейти к Итемам оффера',
    id: '№',
    library: 'Библиотека',
    market: 'Магазин',
    name: 'Название',
    new_offer: 'Новое предложение',
    offer: 'Предложение',
    offer_admin_list: 'Администраторы',
    offer_android_link: 'Ссылка на приложение в PlayMarket',
    offer_android_prefix: 'Префикс Android-приложения',
    offer_banners: 'Баннеры',
    offer_contacts: 'Контакты',
    offer_business_model: 'Бизнес-модель',
    offer_categories: 'Категории',
    offer_cost: 'Стоимость (рубли)',
    offer_created_at: 'Дата создания',
    offer_default_language: 'Язык по умолчанию',
    offer_default_mail_language: 'Язык e-mail писем',
    offer_enable_banners: 'Показ баннеров',
    offer_enable_comments: 'Комментирование',
    offer_is_trial: 'Пробный период',
    offer_enable_external_link: 'Внешняя ссылка для элементов',
    offer_enable_pushes: 'Push-уведомления',
    offer_expire_date: 'Дата окончания',
    offer_grossing_period: 'Период гроссинга (дни)',
    offer_languages: 'Доступные языки интерфейса',
    offer_id: 'ID',
    offer_ios_link: 'Ссылка на приложение в AppStore',
    offer_ios_prefix: 'Префикс iOS-приложения',
    offer_items: 'Элементы',
    offer_name: 'Название',
    offer_new_items_by_default: 'По умолчанию добавлять в предложение новые элементы',
    offer_pins: 'Пин-коды',
    offer_prefix: 'URL-префикс',
    offer_prefix_registration_link: 'Ссылка на регистрацию в оффере',
    offer_settingsBranding: 'Настройки брединга предложения',
    offer_settingsBuild: 'Настройки сборки приложений в предложении',
    offer_settingsContent: 'Настройки контента в предложении',
    offer_settingsRegistration: 'Настройки регистрации в предложении',
    offer_singleapp_ios_enabled: 'SingleAppContainer для iOS-приложения',
    offer_singleapp_android_enabled: 'SingleAppContainer для Android-приложения',
    offer_singleapp_title: 'Название в SingleAppContainer',
    offer_updated_at: 'Дата последнего обновления',
    offer_url_to_hr: 'Административный интерфейс HR',
    offer_url_to_prod: 'Ссылка на web-страницу',
    offers: 'Предложения',
    others: 'Пин-коды',
    notifications: 'Уведомления',
    tokens: 'API-токены',
    tokens_not_found: 'API-токены не найдены',
    token_add_new: 'Создать новый API-токен',
    token_added_new: 'Создан новый API-токен',
    token_deleted_successfully: 'API-токен "{{token}}" удален успешно',
    pin: 'Пин-код предложения',
    pin_code_generation: 'Генерация пин-кодов',
    pin_add_new: 'Создать новыe Пин-коды',
    pin_added_new: 'Создан новый Пин-код',
    pin_user_groups: 'Привязка к группам после регистрации в предложении',
    pin_user_ttl: 'Срок действия подписки пользователя по созданному коду',
    pin_added_0: 'Добавлен {{count}} Пин-код',
    pin_added_1: 'Добавлено {{count}} Пин-кода',
    pin_added_2: 'Добавлено {{count}} Пин-кодов',
    pins: 'Пин-коды предложения',
    pins_not_found: 'Пин-коды не найдены',
    pin_deleted_successfully: 'Пин "{{pin}}" удален успешно',
    pin_erors_were_happend_while_adding_pins: 'Произошли ошибки во время создания Пин-кодов',
    add_pins_value: 'Задать необходимое количество кодов',
    enable_ttl_value: 'Задать срок действия подписки пользователя по созданому коду',
    day_limit_ttl: 'Ограничение (количество дней)',
    prefix: 'Приставка',
    reg_confirmation_required: 'Требуется подтверждение при регистрации',
    reg_email_mask_enabled: 'Фильтрация по email-маске при регистрации',
    reg_email_mask_filter: 'Список email-масок, с которыми можно зарегистрироваться',
    reg_password_change_enabled: 'Пользователь может менять пароль',
    reg_email_mask_filter_example: 'Пример: @example.com',
    reg_group_required: 'Требуется указание группы пользователя при регистрации',
    reg_groups_enabled: 'Группы пользователей включены',
    reg_invites_enabled: 'Регистрация приглашениями',
    reg_ip_mask_enabled: 'IP-фильтрация при регистрации',
    reg_ip_mask_filter: 'Список IP-адресов, с которых можно зарегистрироваться',
    reg_ip_mask_filter_example: 'Пример: 127.0.0.1',
    reg_pins_enabled: 'Использование пин-кодов',
    reg_registration_by_email_enabled: 'Регистрации по email',
    reg_alpina_lab_registration_enabled: 'Автоматическая регистрация в Лаб при регистрации в Библиотеке',
    reg_alpina_lab_enabled: 'Регистрации в Альпина.Лаб доступна',
    reg_registration_enabled: 'Регистрация через промо лендинги включена',
    reg_users_import_enabled: 'Импорт пользователей из файла доступен',
    reg_registration_unlimited: 'Регистрация пользователей не ограничена',
    reg_registration_welcome_mail: 'Отправлять ли пользователю предложения welcome-письмо при регистрации',
    reg_send_b2b_registration_mail: 'Отправлять пользователю письмо при регистрации через b2b API',
    reg_users_allowed: 'Максимальное количество пользователей',
    reg_alpina_lab_users_allowed: 'Максимальное количество пользователей Лаб',
    reg_users_registered: 'Текущее количество пользователей',
    reg_default_users_ttl: 'Срок действия подписки пользоватей',
    reg_default_users_ttl_allow: 'Срок действия подписки ограничен',
    default_users_ttl: 'Укажите нужное количество дней, чтобы установить срок действия подписки. Оставьте 0, если подписка не ограничена.',
    registration: 'Регистрация',
    updating_date: 'Дата обновления',
    integrations: 'Интеграции',
    webhooks: 'Webhooks',
    settings_integration_is_active: 'Интеграция включена',
  },
  orders: {
    new: 'Новый',
    orders: 'Заказы',
    pending: 'Ожидание',
    paid: 'Оплачено',
    cancelled: 'Отменён',
    failed: 'Не удалось оплатить',
    status: 'Статус заказа',
    payments: 'Транзакции по заказу',
    goods: 'Товары',
  },
  payments: {
    currency: 'Валюта',
    total: 'Сумма',
    provider: 'Провайдер',
    transaction: 'ID транзакции по заказу',
    status: 'Статус транзакции',
    paid_at: 'Дата и время завершения транзакции',
  },
  badges: {
    all_badges: 'Все бейджи',
    badge: 'Бейдж',
    badges: 'Бейджи',
    edit_badge: 'Редактировать бейдж',
    image: 'Картинка бейджа',
    name: 'Название бейджа',
    new_badge: 'Новый бейдж',
    ownerOffer: 'Предложение, в котором создан бейдж',
    ownerOfferAdmin: 'Создан администратором',
  },
  banners: {
    all_banners: 'Все баннеры',
    banner: 'Баннер',
    banners: 'Баннеры',
    color: 'Цвет баннера',
    created_at: 'Представление DateTime в заданном формате',
    edit_banner: 'Редактировать баннер',
    generated_link: 'Сгенерированная ссылка',
    image: 'Картинка баннера',
    is_active: 'Баннер активен',
    name: 'Название баннера',
    new_banner: 'Новый баннер',
    offers: 'Предложения, к которым прикреплен баннер',
    ownerOffer: 'Предложения, к которым прикреплен баннер',
    resource_link: 'Идентификатор связи баннера',
    resource_type: 'Тип связи баннера',
    resource_type_category: 'Категория',
    resource_type_item: 'Итем',
    resource_type_url: 'URL',
    resource_type_creator: 'Автор',
    updated_at: 'Представление DateTime в заданном формате',
    weight: 'Вес баннера',
    started_at: 'Старт показа',
    finished_at: 'Окончание показа',
    format: 'Формат изображения',
    theme: 'Тема',
    standard: 'Стандартный: 1408px на 806px',
    'wide_5x2': '5 к 2: 1410px на 564px',
    layout: 'Главная: 750px на 1040px',
    category: 'Внутри категорий: 1280px на 480px',
    common: 'Только у нас: 2052px на 828px',
    common_mobile: 'Только у нас мобильный: 640px на 1332px',
    date_description: 'Даты показа учитываются только при включенной активности баннера',
    image_light: 'Изображение (светлая тема)',
    image_dark: 'Изображение (темная тема)',
  },
  categories: {
    all_categories: 'Все категории',
    badge: 'Бейдж категории',
    categories: 'Категории',
    category: 'Категория',
    childrenCategories: 'Дочерние категории',
    color: 'Цвет категории',
    created_by: 'Кем создано',
    edit_category: 'Редактировать категорию',
    image: 'Картинка категории',
    imageBottom: 'Нижняя картинка категории',
    imageTop: 'Верхняя картинка категории',
    is_default: 'Категория по-умолчанию',
    items: 'Элементы в категории',
    name: 'Название категории',
    new_category: 'Новая категория',
    ownerOffer: 'Предложение, в котором создана категория',
    parentCategory: 'Родительская категория',
    to_items: 'К итемам',
    to_offers: 'К офферам',
    weight: 'Вес(порядок)',
  },
  layouts: {
    all_categories: 'Все лейауты',
    layouts: 'Лейауты',
    layout: 'Лейаут',
    edit_layout: 'Редактировать лейаут',
    blocks: 'Блоки',
    name: 'Название лейаута',
    new_layout: 'Новый лейаут',
    to_offers: 'К офферам',
  },
  blocks: {
    all_blocks: 'Все блоки',
    bg_color: 'Цвет фона',
    type: 'Тип блока',
    items: 'Контент',
    blocks: 'Блоки',
    image: 'Картинка блока',
    references: 'Мини-баннеры',
    block: 'Блок',
    edit_block: 'Редактировать блок',
    name: 'Название блока',
    new_block: 'Новый блок',
    item_types: 'Типы итемов',
    sets_items: 'Подборка',
  },
  creators: {
    first_name: 'Имя',
    full_name: 'Полное имя',
    last_name: 'Фамилия',
    short_name: 'Короткое имя',
    description: 'Описание',
    avatar: 'Изображение',
    title: 'Название',
    type: 'Тип',
    creators: 'Creators',
    go_to_items: 'Перейти к Итемам Создателя',
    grid: {
      title: 'Creators',
    },
    types: {
      author: 'Автор',
      publisher: 'Издательство',
      lecturer: 'Лектор',
      provider: 'Провайдер',
      narrator: 'Чтец',
      speaker: 'Спикер',
    },
  },
  users: {
    additionalOffers: 'Дополнительные предложения, к которым прикреплен пользователь',
    avatar: 'Аватар',
    birthday: 'Дата рождения',
    can_be_restored: 'Пользователь может быть восстановлен после удаления?',
    created_at: 'Дата создания',
    device_id: 'Идентификатор устройства пользователя (aid)',
    downloads: 'Скачиваний',
    email: 'Email',
    expire_date: 'Дата истечения доступа',
    external_id: 'Внешний идентификатор пользователя',
    female: 'Женский',
    first_name: 'Имя',
    gender: 'Пол',
    phone: 'Телефон',
    city: 'Город',
    position: 'Должность',
    department: 'Отдел',
    groups: 'Группы',
    inventory: 'Инвентарь пользователя',
    is_activated: 'Активен?',
    is_auto_registered: 'Пользователь зарегистрирован автоматически',
    is_deleted: 'Удален?',
    is_subscribed: 'Подписан на рассылку?',
    itemsDownloads: 'Итемы, скачанные пользователем',
    itemsFavorite: 'Итемы, добавленные в избранное пользователя',
    itemsInventory: 'Итемы, добавленные в инвентарь пользователя',
    last_name: 'Фамилия',
    subscriptions: 'Подписки',
    labEnabled: 'Доступ в Альпина.Лаб',
    male: 'Мужской',
    offer: 'Предложение',
    outsource: 'Внешний менеджер',
    password: 'Новый пароль',
    promo: 'Промокоды',
    role: 'Роль',
    sales: 'Продажник',
    sso: 'SSO-идентификатор пользователя',
    used_pin: 'Пин-код пользователя, использованный при регистрации',
    user: 'Клиент',
    users: 'Пользователи',
    administrator: 'Главный администратор',
    hr: 'Клиентский администратор',
    support: 'Техническая поддержка',
  },
  usergroups: {
    name: 'Название группы',
    alias: 'Алиас группы',
    usergroups: 'Группы пользователей',
  },
  usersmanagement: {
    offer: 'Предложение',
    emails: 'Архивные почты',
  },
  pushes: {
    title: 'Заголовок',
    message: 'Текст уведомления',
    pushes: 'Push-уведомления',
    offers: 'Предложения',
    status: 'Статус',
    resource_type: 'Привязанный контент',
    created_at: 'Создано',
    resource_link: 'Ссылка',
    resource_type_category: 'Категория',
    resource_type_item: 'Итем',
    resource_type_url: 'URL',
    pending: 'В ожидании',
    enqueued: 'В очереди',
    finished: 'Выполнено',
    users: 'Пользователи',
    user_groups: 'Группы пользователей',
    send_at: 'Время отправления',
    send_at_description: 'Если время не задано, отправление при создании в течение 5 минут',
    to_all: 'Отправлено всем',
    delete_push: `Удалить пуш {{ id }}`,
    types: {
      category: 'Категория',
      book: 'Книга',
      article: 'Статья',
      hybrid: 'Гибрид',
      document: 'Документ',
      audiobook: 'Аудиокнига',
      video: 'Видео',
      course: 'Курсы',
      test: 'Тесты',
      collection: 'Коллекция',
    },
  },
  features: {
    'feature-toggle': 'Feature Toggle',
    feature: 'Feature Toggle',
    features: 'Feature Toggles',
    new_feature: 'Новый Feature Toggle',
    build_versions: 'Версии приложений',
    add_new_feature: 'Создать Feature Toggle',
    name: 'Название Feature Toggle',
    name_column: 'Название Feature Toggle',
    key: 'Ключ',
    description: 'Описание',
    offers: 'Участвуют в тестировании',
    begin_date: 'Дата начала тестирования',
    begin_date_column: 'Дата начала тестирования',
    end_date: 'Дата окончания тестирования',
    is_active: 'Feature Toggle включен',
    is_active_column: 'FT активен',
    platforms: 'Какие платформы поддерживаются',
    edit_feature: 'Редактирование Feature Toggle',
  },
  subscriptions: {
    subscriptions: 'Подписки B2C',
    edit_subscription: 'Редактировать подписку',
    name_column: 'Название подписки',
    add_new_subscription: 'Создать подписку',
    new_subscription: 'Новая подписка',
    name: 'Название подписки',
    title: 'Официальное название для покупки',
    cost: 'Стоимость подписки',
    old_cost: 'Стоимость подписки без скидки',
    trial_days: 'Триальный период',
    expire_days: 'Срок действия подписки',
    have_trial: 'Есть триальный период',
    items: 'Итемы в подписке',
    created_at: 'Создано',
    updated_at: 'Обновлено',
  },
  user_subscriptions: {
    required: 'Обязательное поле',
    user_subscriptions: 'Подписки пользователей',
    user_subscriptions_view: 'Просмотр подписки пользователя',
    new_user_subscription: 'Добавление подписки пользователю',
    not_editable: 'Подписки с указанной стоимостью не доступны к редактированию',
    subscription: 'Подписка',
    user: 'Пользователь',
    from: 'Начало подписки',
    to: 'Окончание подписки',
    cost: 'Стоимость подписки',
    canceled_at: 'Подписка отменена',
    add_new_user_subscription: 'Добавить подписку пользователю',
    edit_user_subscription: 'Редактирование подписки пользователя',
    delete_user_subscription: 'Удалить подписку',
    delete_user_subscription_description: 'Вы увверены, что хотите удалить подписку {{id}}?'
  },
  promocodes: {
    promocodes: 'Промокоды',
    code: 'Код',
    count: 'Допустимое кол-во использований',
    item: 'Итем',
    items: 'Итемы',
    offer: 'Предложение',
    used: 'Кол-во использований',
    expire_date: 'Дата окончания',
    is_active: 'Активно?',
    grid: {
      title: 'Промокоды',
    },
    subscriptions: 'Подписки',
    trial_days: 'Триальный период',
    expire_days: 'Длительность подписки',
    promocode_type: 'Тип промокода',
    promocode_type_subscriptions: 'Подписки',
    promocode_type_item: 'Итемы',
    discount_type_price: 'Размер скидки в рублях',
    discount_type_percent: 'Размер скидки в процентах',
    discount_type: 'Выбор типа скидки',
    discount_price: 'Размер скидки в рублях',
    discount_percent: 'Размер скидки в процентах',
  },
  sets: {
    sets: 'Подборки',
    set: 'Подборка',
    add_new_set: 'Новая подборка',
    new_set: 'Новая подборка',
    edit_set: 'Редактирование',
    name: 'Название',
    description: 'Описание',
    items: 'Итемы',
    image: 'Изображение',
    offers: 'Офферы',
    creators: 'Авторы',
  },
  popular_requests: {
    popular_requests: 'Популярные запросы',
    new_popular_request: 'Добавить новый',
    edit_popular_request: 'Редактирование',
    label: 'Название',
    is_active: 'Активно',
    offers: 'Предложения',
    content_title: 'Идентификатор связи',
    content_type: 'Тип связи',
    content_type_category: 'Категория',
    content_type_item: 'Итем',
    content_type_url: 'URL',
    generated_link: 'Сгенерированная ссылка',
    weight: 'Вес(порядок)',
  },
  autoimport: {
    enabled: 'Итемы включены',
    disabled: 'Итемы выключены',
  },
  header: {
    logout: 'Выйти',
  },
  dict: {
    active: 'Активен',
    add_row: 'Добавить строку',
    available: 'Доступен',
    add: 'Добавить',
    amount: 'Количество',
    not_active: 'Неактивен',
    welcome: 'Добро пожаловать',
    view: 'Просмотреть',
    edit: 'Редактировать',
    inventory: 'Инвентарь',
    delete: 'Удалить',
    updated: 'Обновлено',
    created: 'Создано',
    deleted: 'Удалено',
    deleted2: 'Удален',
    error: 'Ошибка',
    save: 'Сохранить',
    cancel: 'Отмена',
    name: 'Название',
    hide: 'Скрыть',
    more_with_dots: 'Ещё...',
    not_selected: 'Не выбрано',
    not_set: 'Не назначено',
    on: 'вкл',
    off: 'выкл',
    yes: 'да',
    no: 'нет',
    day_0: '{{count}} день',
    day_1: '{{count}} дня',
    day_2: '{{count}} дней',
    rouble_0: '{{count}} рубль',
    rouble_1: '{{count}} рубля',
    rouble_2: '{{count}} рублей',
    piece_0: '{{count}} штука',
    piece_1: '{{count}} штуки',
    piece_2: '{{count}} штук',
    weight_order: 'Вес(порядок)',
    external_link: 'Внешняя ссылка',
    created_at: 'Дата создания',
    updated_at: 'Дата обновления',
    type: 'Тип',
  },
  forms: {
    amount_of_added_records: 'Добавлено записей: {{amount}}',
    amount_of_deleted_records: 'Удалено записей: {{amount}}',
    cancel: 'Отмена',
    example: 'Пример: {{text}}',
    expected_size: 'Требуемый размер: {{width}}px на {{height}}px',
    expected_range: 'Минимальный размер: {{minWidth}}px на {{minHeight}}px. Максимальный размер: {{maxWidth}}px на {{maxHeight}}px',
    expected_width: 'Требуемая ширина: {{width}}px',
    required_field: 'Поле «{{field}}» обязательно к заполнению',
    empty_field: 'Поле «{{field}}» не может быть пустым',
    supported_extentions: 'Поддерживаемые расширения: {{ext}}',
    wrong_format: 'Поле «{{field}}» не соответствует требуемому формату',
    wrong_ip_address: 'Поле «{{field}}» должно быть в формате «x.x.x.x», где x это числа от 0 до 255',
    wrong_email: 'Поле «{{field}}» должно быть в формате «@some-domain.com»',
    wrong_hr_email: 'Поле «{{field}}» должно быть в формате «example@domain.com»',
    wrong_min_value: 'Поле «{{field}}» должно быть не меньше {{value}}',
    wrong_max_value: 'Поле «{{field}}» должно быть не больше {{value}}',
    wrong_integer: 'Поле «{{field}}» должно быть целым числом',
    wrong_positive_value: 'Поле «{{field}}» должно быть больше либо равно «0»',
    wrong_max_string_size: 'Поле «{{field}}» должно содержать не больше «{{amount}}» символа',
    wrong_field_already_has_element: 'Поле «{{field}}» уже содержит элемент «{{value}}»',
    wrong_password_can_not_be_made_of_spaces: 'Пароль не может состоять из пробелов',
    updating_after_saving: 'Обновляется после сохранения',
    must_be_greater_than_0: 'Должно быть числом больше «0»',
    item_was_removed: 'Итем «{{id}}» удален',
    delete_confirmation_question: 'Вы действительно хотите безвозвратно удалить запись №{{id}}?',
    delete_record: 'Удалить запись',
    max_string_size: 'Максимальное количество символов: {{value}}',
  },
  errors: {
    can_not_create_inner_link: 'Невозможно создать внутреннюю ссылку',
    data_is_not_found: 'Данные не найдены',
    data_is_empty: 'Нет дaнных',
    list_already_has_item: 'Список уже содержит данный элемент',
    wrong_login_or_password: 'Неверный логин или пароль',
    unexpected_error_occurred: 'Произошла непредвиденная ошибка',
    unexpected_network_error_occurred: 'Произошла непредвиденная сетевая ошибка',
  },
  backend: {
    errors: {
      validation: 'Ошибка валидации',
    },
  },
};
